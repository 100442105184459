import { createContext, useContext, useEffect, useReducer } from "react";
import { initialState, reducer } from "../reducer/reducer";
import { fetchShisha } from "../utils/csvUtils";

export const BabelContext = createContext();
export const BabelProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const [shishaArray] = await Promise.all([
          fetchShisha(),
        ]);

        dispatch({ type: "SET_SHISHA_DATA", payload: shishaArray });
      } catch (error) {
        console.error("Encountered an error:", error);
      }
    };

    fetchData();
  }, []); // Empty dependency array ensures this effect runs only once on mount
  return (
    <BabelContext.Provider value={{ state, dispatch }}>
      {children}
    </BabelContext.Provider>
  );
};
export const useBabel = () => useContext(BabelContext);
