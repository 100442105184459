/* eslint-disable no-unused-vars */
import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const Brunch = forwardRef((props, ref) => {
  const { t, i18n } = useTranslation();
  return (
    <BrunchDiv ref={ref}>
      {/* change classname to scroll-box2 to add vertical scrolling for a small box if text is too long */}
      <div>
        {/* <img src={pageTwoText} alt="Page-2" title={t("h-t-h-o-title")} /> */}
        <h2 title={t("brunch-h-o")}>{t("brunch-h-o")}</h2>
        <p>{t("brunch-p-o")}</p>
        <div>
          <a
            href={`/pdf/BABEL_BRUNCH_${i18n.language.toLocaleUpperCase()}.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            Menu
          </a>
          <a
            href={`/pdf/BABEL_Drink_Menu_${i18n.language.toLocaleUpperCase()}.pdf`}
            rel="noopener noreferrer"
            target="_blank"
          >
            {i18n.language === "en" ? "Drinks" : "Boissons"}
          </a>
        </div>
      </div>
    </BrunchDiv>
  );
});
const BrunchDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("../img/menu/brunch-menu.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  position: relative;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  & > div:nth-child(1) {
    ::-webkit-scrollbar {
      width: 8px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.2);
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 1);
    }
    /* overflow-y: auto;
    scroll-behavior: smooth; */
    height: auto;
    /* max-height: 68vh; */
    width: 100%;
    max-width: 600px;
    position: absolute;
    z-index: 1;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 20px 40px;
    margin-right: 40px;
    & > h2 {
      height: auto;
      width: 100%;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      /* align-self: end; */
      /* margin: 20px 200px 20px 0; */
    }
    /* & > img {
      height: auto;
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
    } */
    & > p {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      & > span {
        font-weight: 700;
      }
      /* align-self: end; */
      /* margin-right: 200px; */
    }
    & > div {
      display: flex;
      justify-content: space-between;
      align-items: center;
      & > a {
        border: 1px solid var(--babel-grey);
        font-size: 16px;
        padding: 10px;
        width: 100%;
        max-width: 250px;
        text-align: center;
        cursor: pointer;
        transition: width 1.2s;
        font-weight: 300;
        background: var(--babel-turquoise);
        color: var(--black);
        /* background: rgba(0, 0, 0, 0.25); */
        &:hover {
          background: var(--title-color);
          border: 1px solid var(--title-color);
        }
      }
    }
  }
  @media (max-width: 991px) {
    height: auto;
    min-height: 100vh;
    padding: 20px 0 100px 0;
    /* overflow-y: visible; */
    background-image: url("../img/menu/bruch-lounge-story-mobile.webp");
    background-size: contain;
    background-position: top;
    top: 0;
    justify-content: center;
    align-items: unset;
    & > div:nth-child(1) {
      max-height: unset;
      position: unset;
      justify-content: flex-end;
      padding: 20px;
      margin-right: 0px;
      padding-top: 80vw;
      text-align: center;
      /* & > img {
        width: auto;
      } */
      & > h2 {
        font-size: 36px;
        line-height: 40px;
        margin: 0 0 24px 0;
      }
      & > p {
        max-width: unset;
        width: 100%;
        margin-right: 0;
        font-size: 16px;
        margin-bottom: 12px;
      }
      & > div {
        flex-direction: column;
        gap: 20px;
        & > a {
          margin: 0 auto;
        }
      }
    }
  }
`;
export default Brunch;
