import React from "react";
import styled from "styled-components";
import { RiMapPin2Line, RiPhoneFill } from "react-icons/ri";
import { GoMail } from "react-icons/go";
import { FiClock } from "react-icons/fi";
import { GoCreditCard } from "react-icons/go";
import GoogleMap from "../components/GoogleMap";
import { useTranslation } from "react-i18next";

const Contact = () => {
  const { t, i18n } = useTranslation();
  return (
    <>
      <ContactInfoDiv>
        <main>
          <h2>{t("contact.info")}</h2>
          <div>
            <RiMapPin2Line />
            <span>
              {/* {" "}
              <h4>{t("contact.address")}:</h4> */}
              <p>
                {i18n.language === "en"
                  ? "1500 Unit C, QC-138"
                  : "1500 Unité C, QC-138"}
                {i18n.language === "en"
                  ? "Kahnawake, Quebec"
                  : "Kahnawake, Québec"}{" "}
                J0L 1B0
              </p>
            </span>
          </div>
          <div>
            <RiPhoneFill />
            <span>
              {/* <h4>{t("contact.telephone")}:</h4> */}
              <p>
                <a href="tel:1-450-635-9285">1-450-635-9285</a>
              </p>
            </span>
          </div>
          <div>
            <GoMail />
            <span>
              {/* <h4>{t("contact.email")}:</h4> */}
              <p>
                <a href="mailto:info@babelpg.ca">info@babelpg.ca</a>
              </p>
            </span>
          </div>
          <div>
            <FiClock />
            <span>
              {/* {" "}
              <h4>{t("contact.hours")}:</h4> */}
              {i18n.language === "en" ? (
                <>
                  <p>Monday to Sunday - 5:00PM to 1:00AM</p>
                </>
              ) : (
                <>
                  <p>Lundi au dimanche - de 17h00 à 1h00</p>
                </>
              )}
            </span>
          </div>
          <div>
            <GoCreditCard />
            <span>
              {/* {" "}
              <h4>{t("contact.hours")}:</h4> */}
              {i18n.language === "en" ? (
                <>
                  <p>A Playground player’s card is required to gain entry</p>
                </>
              ) : (
                <>
                  <p>Accès réservé aux joueurs munis d’une carte Playground</p>
                </>
              )}
            </span>
          </div>
        </main>
        <MapDiv samesite="strict">
          <GoogleMap />
        </MapDiv>
      </ContactInfoDiv>
    </>
  );
};
const ContactInfoDiv = styled.div`
  height: auto;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  /* gap: 20%; */
  align-items: center;
  font-weight: 300;
  padding: 0 40px;
  gap: 15%;
  & > main {
    align-self: flex-start;
    margin-top: 60px;
    height: auto;
    /* max-height: 500px; */
    display: flex;
    flex-flow: column nowrap;
    justify-content: space-between;
    gap: 20px;
    & > h2 {
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      font-weight: 700;
      font-style: italic;
      white-space: nowrap;
    }
    & > div {
      /* margin-bottom: 30px; */
      display: flex;
      align-items: flex-start;
      & > svg {
        /* margin-right: 10px; */
        height: 22px;
      }
      & > span {
        & > p {
          margin: 0;
          padding: 0;
          margin-left: 10px;
          line-height: 24px;
          font-size: 16px;
        }
        /* & > h4 {
          display: inline-flex;
          text-transform: uppercase;
          font-weight: 400;
          margin: 0;
          font-size: 24px;
          color: var(--babel-grey);
        } */
      }
    }
    /* & > div:nth-child(2) {
      & > span {
        & > p {
          margin-bottom: 12px;
          line-height: 18px;
        }
      }
    } */
  }
  @media screen and (max-width: 389px) and (min-width: 360px) {
    /* start of small mobile styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    padding: 20px;
    margin-top: 0;
    main {
      align-self: center;
      width: 100%;
      max-width: 500px;
      margin-top: 20px;
      gap: 0;
      & > h2 {
        font-size: 36px;
        line-height: 40px;
        margin: 16px 0;
        white-space: normal;
      }
      & > div {
        margin-bottom: 20px;
        & > svg {
          width: 18px;
          height: 18px;
        }
        & > span {
          & > p {
            margin: 0 0 0 30px;
            font-size: 16px;
            line-height: 28px;
          }
          /* & > h4 {
            font-size: 20px;
          } */
        }
      }
      & > div:nth-child(2) {
        & > span {
          & > p {
            margin-bottom: 0;
            line-height: 24px;
          }
        }
      }
    }
  }
`;
const MapDiv = styled.div`
  height: 500px;
  width: 100%;
  max-width: 500px;
  padding: 20px 0;
  border-bottom: 2px solid var(--babel-grey);
  border-top: 2px solid var(--babel-grey);
  position: relative;
  & > iframe {
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (max-width: 991px) {
    /* start of phone and tablet styles */
    padding: 10px;
    height: 280px;
  }
`;
export default Contact;
