import React, {
  useState,
  // useCallback,
  // useRef,
  useEffect,
  forwardRef,
} from "react";
import styled, { keyframes } from "styled-components";
import { useTranslation } from "react-i18next";
import { useBabel } from "../context/BabelContext";

export const carouselContent = [
  {
    header: "Mezze",
    content: "A Culinary Odyssey.",
    content_two:
      "Embark on a flavorful journey through our menu, where the rich heritage of Mediterranean cuisine is celebrated in every dish. Dive into our Taouk-Style Fried Chicken Sandwich, a spicy and savory delight that captures the essence of Mediterranean street food, served alongside our innovative Hummus Sunset, a harmonious blend of muhammara and hummus. Our selection of mezze is designed to tantalize the taste buds and nourish the soul, with each creation paying tribute to the vibrant cultures that fuel our culinary exploration. Crafted with the finest local ingredients and a generous sprinkle of warmth and hospitality, every dish invites you to share, savor, and create lasting memories.",
    button: "Mezze menu",
    button_url: "BABEL_MEZZE_",
    title: "Mezze served at Babel Lounge",
    image_url: "../img/menu/mezze-menu.jpg",
    image_url_mobile: "../img/menu/mezze-menu-mobile.jpg",
    alt: "Page 4 - Section 1",
    header_fr: "Mezze",
    content_fr: "Une Odyssée Culinaire.",
    content_two_fr:
      "Embarquez pour un voyage savoureux à travers notre menu, où le riche héritage de la cuisine méditerranéenne est célébré dans chaque plat. Plongez dans notre Sandwich de Poulet Frit à la Taouk, une délice épicée et savoureuse qui capture l'essence de la cuisine de rue méditerranéenne, servi à côté de notre innovant Hummus Sunset, un mélange harmonieux de muhammara et hummus. Notre sélection de mezze est conçue pour titiller les papilles et nourrir l'âme, avec chaque création rendant hommage aux cultures vibrantes qui alimentent notre exploration culinaire. Fabriqué avec les meilleurs ingrédients locaux et une généreuse pincée de chaleur et d'hospitalité, chaque plat vous invite à partager, savourer et créer des souvenirs durables.",
    button_fr: "Menu mezze",
    title_fr: "Mezze ssrevis au Babel Lounge",
  },
  {
    header: "Cocktails",
    content: "The Art of Mixology.",
    content_two:
      "At Babel, the cocktail is elevated to an art form. Our mixologists conjure up magic in a glass, blending exotic ingredients with local herbs and spices to create concoctions that dazzle and delight. Whether you're in the mood for a refreshing burst of flavor or a slow sip of sophistication, our cocktails are curated to complement the unique notes of our shisha and mezze, offering a seamless sensory experience.",
    button: "Cocktails menu",
    button_url: "BABEL_COCKTAIL_",
    title: "Cocktails served at Babel Lounge",
    image_url: "/img/menu/cocktails-menu.jpg",
    image_url_mobile: "/img/menu/cocktails-menu-mobile.jpg",
    alt: "Page 4 - Section 2",
    header_fr: "Cocktails",
    content_fr: "L'Art de la Mixologie.",
    content_two_fr:
      "À Babel, le cocktail est élevé au rang d'art. Nos mixologues créent de la magie dans un verre, mélangeant des ingrédients exotiques avec des herbes et épices locales pour créer des concoctions qui éblouissent et enchantent. Que vous soyez d'humeur pour une explosion rafraîchissante de saveur ou une lente gorgée de sophistication, nos cocktails sont conçus pour compléter les notes uniques de notre shisha et mezze, offrant une expérience sensorielle sans faille.",
    button_fr: "Menu cocktails",
    title_fr: "Cocktails servies au Babel Lounge",
  },
  // {
  //   header: "Shisha",
  //   content:
  //     "Babel, the epitome of Hookah Lounge sophistication, invites you to experience the alchemy of our artisanal cocktails. Each libation is a crafted masterpiece, designed to complement your hookah session.",
  //   content_two:
  //     "From classic concoctions to inventive blends, our cocktails promise an unforgettable evening of flavors that dance on your palate.",
  //   button: "Menu shisha",
  //   title: "Shisha served at Babel Lounge",
  //   image_url: "/img/menu/shisha-menu.jpg",
  //   alt: "Page 4 - Section 3",
  //   header_fr: "Shisha",
  //   content_fr:
  //     "Babel, le summum de la sophistication des salons de Hookah, vous invite à découvrir l’alchimie de ses cocktails artisanaux. Chaque création est un chef-d’œuvre conçu pour accompagner votre séance de Hookah. ",
  //   content_two_fr:
  //     "Des concoctions classiques aux mélanges inventifs, nos cocktails promettent une soirée inoubliable de saveurs qui dansent sur votre palais.",
  //   button_fr: "Menu shisha",
  //   title_fr: "Shisha servis au Babel Lounge",
  // },
];

const Menu = forwardRef((props, ref) => {
  const [squareButton, setSquareButton] = useState(null);
  const [squareButton2, setSquareButton2] = useState(null);
  const {
    state: { selectedMenu },
    dispatch,
  } = useBabel();
  // const timeoutRef = useRef();
  const { t, i18n } = useTranslation();
  const [imageSrc, setImageSrc] = useState("");

  useEffect(() => {
    const timer = setTimeout(() => {
      setImageSrc(i18n.language === "en" ? carouselContent[squareButton].image_url : carouselContent[squareButton].image_url_mobile);
    }, 840);

    return () => clearTimeout(timer);
  }, [i18n.language, squareButton]);

  useEffect(() => {
    if (!selectedMenu.menu_select) {
      const timer = setTimeout(() => {
        setSquareButton2((prev) =>
          prev === carouselContent.length - 1 ? 0 : prev + 1
        );
      }, 1200);
      return () => clearTimeout(timer);
    }
  }, [squareButton, selectedMenu.menu_select]);

  const squareButtonFn = (value) => {
    setSquareButton(value);
    dispatch({ type: "select-menu", payload: false });
  };
  useEffect(() => {
    if (selectedMenu.item === "mezze") {
      setSquareButton(0);
      setSquareButton2(1);
    } else if (selectedMenu.item === "cocktails") {
      setSquareButton(1);
      setSquareButton2(1);
    }
  }, [selectedMenu.item]);
  // const resetTimeout = () => {
  //   if (timeoutRef.current) {
  //     clearTimeout(timeoutRef.current);
  //   }
  // };

  // const autoPlay = useCallback(() => {
  //   setSquareButton((prev) =>
  //     prev === carouselContent.length - 1 ? 0 : prev + 1
  //   );
  // }, [setSquareButton]);

  // useEffect(() => {
  //   resetTimeout();
  //   if (props.view4) {
  //     timeoutRef.current = setTimeout(() => autoPlay(), 6000);
  //   }
  //   return () => {
  //     resetTimeout();
  //   };
  // }, [props.view4, autoPlay, squareButton]);
  return (
    <>
      <MenuDiv view4={{ squareButton }} key={squareButton}>
        <div>
          <div>
            <h3
              title={
                i18n.language === "en"
                  ? t(carouselContent[squareButton2]?.title)
                  : t(carouselContent[squareButton2]?.title_fr)
              }
            >
              {i18n.language === "en"
                ? t(carouselContent[squareButton2]?.header)
                : t(carouselContent[squareButton2]?.header_fr)}
            </h3>
          </div>
          <span></span>
          <p>
            <span>
              {i18n.language === "en"
                ? carouselContent[squareButton2]?.content
                : carouselContent[squareButton2]?.content_fr}
            </span>{" "}
            {i18n.language === "en"
              ? carouselContent[squareButton2]?.content_two
              : carouselContent[squareButton2]?.content_two_fr}
          </p>
          {carouselContent[squareButton2]?.button !== "" ? (
            <div>
              <a
                href={`/pdf/${
                  carouselContent[squareButton2]?.button_url
                }${i18n.language.toLocaleUpperCase()}.pdf`} // Replace with the correct a path
                rel="noopener noreferrer"
                target="_blank"
                alt={carouselContent[squareButton2]?.alt}
                title={
                  i18n.language === "en"
                    ? carouselContent[squareButton2]?.title
                    : carouselContent[squareButton2]?.title_fr
                }
              >
                {i18n.language === "en"
                  ? carouselContent[squareButton2]?.button
                  : carouselContent[squareButton2]?.button_fr}
              </a>
            </div>
          ) : null}
        </div>
        <img src={imageSrc} alt="bg-page-four" />
        <div></div>
      </MenuDiv>
      <SquareButtons>
        {carouselContent?.map((el, ind) => (
          <li key={ind * Math.random() * 1400}>
            <span
              className={squareButton === ind ? "active" : undefined}
              onClick={() => squareButtonFn(ind)}
            >
              {""}
            </span>
          </li>
        ))}
      </SquareButtons>
    </>
  );
});
const slideRight = keyframes`
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
  `;
const slideLeft = keyframes`
  0% {
    transform: translateX(100%);
  }
  100% {
    transform: translateX(0%);
  }
`;
const alignRight = keyframes`
  0% {
    text-align: left;
  }
  100% {
    text-align: right;
  }
`;
const alignLeft = keyframes`
  0% {
    text-align: right;
  }
  100% {
    text-align: left;
  }
`;
const slideDown = keyframes`
  0% {
    transform: translateY(-100%);
  }
  100% {
    transform: translateY(100%);
  }
`;
const textColor = keyframes`
    0%      {color :var(--babel-grey)}
    25%     {color:var(--babel-grey)}
    90%     {color:var(--black)}
    100%    {color :var(--babel-grey)}
`;
const borderColor = keyframes`
    0%      {border-color :var(--babel-grey); color :var(--babel-grey);}
    25%     {border-color:var(--babel-grey); color:var(--babel-grey);}
    90%     {border-color:var(--black); color:var(--black);}
    100%    {border-color :var(--babel-grey); color :var(--babel-grey);}
`;
const whiteDropAnimation = keyframes`
  0% {
    height: 0;
    opacity: 0;
  }
  20% {
    height: 50%;
    opacity: 0.75;
  }
  50% {
    height: 100%;
    opacity: 1;
  }
  100% {
    height: 100%;
    opacity: 1;
  }
`;
const fadeIn = keyframes`
  from {
    opacity: 0.9;
  }
  to {
    opacity: 1;
  }
`;
const MenuDiv = styled.div`
  position: relative;
  z-index: 5;
  height: 100vh;
  width: 100vw;
  & > div:nth-child(1) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    max-width: 1000px;
    display: flex;
    flex-flow: column nowrap;
    z-index: 12;
    padding: 40px;
    animation: ${textColor} 1.4s;
    & > div:first-child {
      margin-bottom: 24px;
      width: 50%;
      animation: ${fadeIn} 0.2s ease-in,
        ${({ view4 }) =>
            view4.squareButton % 2 === 0 ? slideLeft : slideRight}
          1s 1s forwards;
      transform: ${({ view4 }) =>
        view4.squareButton % 2 !== 0 ? "translateX(0%)" : "translateX(100%)"};
      & > h3 {
        /* text-align: center; */
        text-transform: uppercase;
        font-size: 56px;
        line-height: 60px;
        text-transform: uppercase;
        font-weight: 700;
        font-style: italic;
        white-space: nowrap;
        width: 100%;
        text-align: center;
        margin: 0;
        animation: ${fadeIn} 0.2s ease-in,
          ${({ view4 }) =>
              view4.squareButton % 2 === 0 ? alignLeft : alignRight}
            1s 1s forwards;
        text-align: ${({ view4 }) =>
          view4.squareButton % 2 !== 0 ? "left" : "right"};
      }
    }
    & > span {
      width: 100%;
      border-bottom: 2px solid;
      transition: border-bottom-color 1.5s forwards;
    }
    & > p {
      height: auto;
      min-height: 150px;
      width: 50%;
      line-height: 24px;
      margin-top: 24px;
      width: 50%;
      margin-bottom: 32px;
      animation: ${fadeIn} 0.2s ease-in,
        ${({ view4 }) =>
            view4.squareButton % 2 === 0 ? slideRight : slideLeft}
          1s 1s forwards;
      transform: ${({ view4 }) =>
        view4.squareButton % 2 === 0 ? "translateX(0%)" : "translateX(100%)"};
      & > span {
        font-weight: 300;
      }
    }
    & > div:nth-child(4) {
      width: 50%;
      animation: ${fadeIn} 0.2s ease-in,
        ${({ view4 }) =>
            view4.squareButton % 2 === 0 ? slideRight : slideLeft}
          1s 1s forwards;
      transform: ${({ view4 }) =>
        view4.squareButton % 2 === 0 ? "translateX(0%)" : "translateX(100%)"};
      margin-top: 40px;
      display: flex;
      & > a {
        border: 1px solid var(--babel-grey);
        animation: ${borderColor} 1.8s;
        font-size: 16px;
        padding: 10px;
        width: 100%;
        max-width: 300px;
        text-align: center;
        cursor: pointer;
        transition: width 1.2s;
        font-weight: 300;
        /* background: rgba(0, 0, 0, 0.25); */
        &:hover {
          background: var(--babel-turquoise);
          color: var(--black);
          border: 1px solid var(--babel-turquoise);
        }
      }
    }
  }
  & > img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    height: 100vh;
    width: 100vw;
    //in question
    transition: opacity 1s ease-in-out;
    animation: ${fadeIn} 1s 1s ease-in;
  }
  & > div:last-child {
    position: absolute;
    width: 100vw;
    right: 0;
    left: 0;
    background-color: var(--babel-grey);
    z-index: 5;
    transition: opacity 1s;
    animation: ${whiteDropAnimation} 1s forwards, ${slideDown} 3s forwards;
    opacity: 0;
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:nth-child(1) {
      padding: 20px;
      & > div:first-child {
        width: 48%;
        & > h3 {
          font-size: 35px;
          line-height: 40px;
          font-weight: 700;
        }
      }
      & > span {
        /* margin: 10px auto; */
      }
      & > p {
        font-size: 16px;
        margin-top: 24px;
        margin-bottom: 0;
        transform: none;
        animation: ${fadeIn} 1s ease-in;
        width: 100%;
      }
      & > div:nth-child(4) {
        margin-top: 24px;
        & > a {
          font-size: 16px;
          padding: 5px 10px;
        }
      }
    }
    & > img {

    }
  }
`;
const SquareButtons = styled.ul`
  position: absolute;
  left: 50%;
  bottom: 10%;
  transform: translate(-50%, 0);
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-evenly;
  width: 100%;
  max-width: 500px;
  transition: 1s ease-in-out;
  padding: 0;
  z-index: 5;
  li {
    list-style: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid var(--babel-grey);
    width: 24px;
    height: 24px;
  }
  span:hover {
    background-color: var(--babel-grey);
    transition: background-color 0.4s;
  }
  span {
    width: 16px;
    height: 16px;
    cursor: pointer;
    &.active {
      background-color: var(--babel-grey);
      transition: background-color 0.4s;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
  }
`;
export default React.memo(Menu);
