import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
  }
:root {
  --red: #cd0000;
  --black: #000000;
  --lightblack: #111111;
  --grey: #818181;
  --darkgrey: #3d3d3d;
  --white: #fff;
  --lightgrey: #f9f9f9;
  --rewards_grid:  #2C2C2C;
  --babel-grey: #cdc5bf;
  --babel-turquoise: #bdcab9;
  --gold: #c59b6b;
  --navbar-bg: #f5f5f5;
  --title-color: #c3996a;
}
html {
  scroll-behavior: smooth;
}
body {
  font-family: 'Inter', sans-serif;
  background: #000;
  color: var(--babel-grey);
  scroll-behavior: smooth;
  ul, ol {
    list-style: none;
    color: inherit;
    font-weight: 300;
  }
  a {
    text-decoration: none;
    color: inherit;
  }
  p {
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    font-weight: 300;
  }
  h1, h2, h3 ,h4, h5, h6 {
    padding: 0;
    margin: 0;
    margin-bottom: 24px;
    font-family: 'Noto Serif Display', serif;
    font-weight: 700;
    color: var(--title-color);
  }
  button {
    cursor: pointer;
  }
  input[type="checkbox"] {
    appearance: none;
    background-color: inherit;
    margin: 0;
    font: inherit;
    color: var(--babel-grey);
    width: 1.15em;
    height: 1.15em;
    border: 0.001em solid var(--babel-grey);
    border-radius: 0.15em;
    transform: translateY(-0.075em);
  }
  input[type="checkbox"] {
    display: grid;
    place-content: center;
  }

  input[type="checkbox"]::before {
    content: "";
    width: 0.85em;
    height: 0.85em;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    background-color: var(--babel-turquoise);
  }
  input[type="checkbox"]:checked::before {
    transform: scale(1);
  }
}
::-webkit-scrollbar {
  width: 0;
  height: 0;
}
`;

export default GlobalStyle;
