import React from "react";
import styled from "styled-components";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { Noindex } from "../Noindex";

const PrivacyPolicy = () => {
  const { t } = useTranslation();

  return (
    <PrivacyPolicyDiv>
      <div>
        <h1>{t("privacyPolicy.title")}</h1>

        <p>{t("privacyPolicy.introduction")}</p>
        <p>{t("privacyPolicy.information")}</p>
        <p>{t("privacyPolicy.informationContent")}</p>
        <ul>
          <li>{t("privacyPolicy.informationPoint1")}</li>
          <li>{t("privacyPolicy.informationPoint2")}</li>
          <li>{t("privacyPolicy.informationPoint3")}</li>
        </ul>

        <h3>{t("privacyPolicy.howWeUse")}</h3>
        <p>{t("privacyPolicy.howWeUseContent")}</p>
        <ul>
          <li>{t("privacyPolicy.howWeUsePoint1")}</li>
          <li>{t("privacyPolicy.howWeUsePoint2")}</li>
          <li>{t("privacyPolicy.howWeUsePoint3")}</li>
          <li>{t("privacyPolicy.howWeUsePoint4")}</li>
        </ul>

        <h3>{t("privacyPolicy.howWeStore")}</h3>
        <p>{t("privacyPolicy.howWeStoreContent")}</p>

        <h3>{t("privacyPolicy.sharingInformation")}</h3>
        <p>{t("privacyPolicy.sharingInformationContent")}</p>

        <h3>{t("privacyPolicy.cookies")}</h3>
        <p>{t("privacyPolicy.cookiesContent")}</p>

        <h3>{t("privacyPolicy.yourRights")}</h3>
        <p>{t("privacyPolicy.yourRightsContent")}</p>

        <h3>{t("privacyPolicy.changes")}</h3>
        <p>{t("privacyPolicy.changesContent")}</p>

        <h3>{t("privacyPolicy.contactUs")}</h3>
        <p
          dangerouslySetInnerHTML={{
            __html: t("privacyPolicy.contactUsContent"),
          }}
        ></p>
      </div>
      <Footer />
      <Noindex />
    </PrivacyPolicyDiv>
  );
};

const PrivacyPolicyDiv = styled.div`
  width: 100%;
  height: auto;
  overflow-x: hidden;
  padding: 80px 0;
  position: relative;
  & > div:first-child {
    padding: 40px 20px;
    margin: 0 auto;
    height: auto;
    width: 100%;
    max-width: 1440px;
    display: flex;
    flex-direction: column;
    & > h1 {
      font-size: 65px;
      line-height: 65px;
      text-transform: capitalize;
    }
    & > h3 {
      margin: 20px 0;
      text-transform: capitalize;
      font-size: 20px;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > div:first-child {
      & > h1 {
        font-size: 36px;
        line-height: 40px;
      }
      & > p {
        margin-bottom: 12px;
      }
    }
  }
`;

export default PrivacyPolicy;
