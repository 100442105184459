export const initialState = {
  menuBarToggle: false,
  viewIndex: 1,
  activeFlavor: {
    title: "Guava Island",
    description: "Guava, orange and cool fresh mint",
    price: "40",
    title_fr: "Île de la Goyave",
    description_fr: "Goyave, menthe à l'orange, et fraîcheur glacée",
    category: "House Blends",
    category_fr: "Mélanges Maison",
    flavor: 1,
  },
  contactInfo: {
    _id: "",
    name: "",
    email: "",
    message: "",
    first: false,
    second: false,
  },
  contacts: [],
  navbarVisible: true,
  shishaData: [],
};
export const reducer = (state, { type, payload }) => {
  switch (type) {
    case "menu-toggle":
      return { ...state, menuBarToggle: payload };
    case "view-index":
      return { ...state, viewIndex: payload };
    case "category":
      return { ...state, category: payload };
    case "active-flavor":
      return {
        ...state,
        activeFlavor: payload,
      };
    case "input":
      return {
        ...state,
        contactInfo: { ...state.contactInfo, [payload.name]: payload.value },
      };
    case "contact-info":
      return { ...state, contacts: [payload, ...state.contacts] };
    case "navbar-scroll":
      return { ...state, navbarVisible: payload };
    case "reset":
      // Handle resetting the contactInfo to its initial state
      return {
        ...state,
        contactInfo: initialState.contactInfo,
      };
    case "SET_SHISHA_DATA":
      return { ...state, shishaData: payload };
    default:
      return state;
  }
};
