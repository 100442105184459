/* eslint-disable no-unused-vars */
import React from "react";
import { Route, Routes, useLocation } from "react-router-dom";
import styled from "styled-components";
import Navbar from "./components/Navbar";
import ErrorPage from "./pages/ErrorPage";
import HomePage from "./pages/HomePage";
import CookieConsent from "react-cookie-consent"; // getCookieConsentValue, // Cookies,
import { useTranslation, I18nextProvider } from "react-i18next";
import { useBabel } from "./context/BabelContext";
import { SEO } from "./SEO";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import TermsOfUse from "./pages/TermsOfUse";
import { changeLanguage } from "./i18n";

function App() {
  const location = useLocation();
  const { state } = useBabel();
  const { i18n, t } = useTranslation();
  // window.onbeforeunload = () => {
  //   window.scrollTo({
  //     top: 0,
  //     left: 0,
  //     behavior: "smooth",
  //   });
  // };

  return (
    <MainDiv>
      <I18nextProvider i18n={i18n}>
        <SEO
          item={{
            title: t("seo-title"),
            description: t("seo-description"),
            url: "https://babelpg.ca/",
            image: "/seo_images/babel-lounge.jpg",
          }}
        />
        {(location.pathname === "/" ||
          location.pathname === "/fr" ||
          location.pathname === "/privacy-policy" ||
          location.pathname === "/terms-of-use") && <Navbar />}
        <CookieConsent
          enableDeclineButton
          location="bottom"
          buttonText="Ok"
          declineButtonText="Decline"
          cookieName="babel"
          cookieValue={JSON.stringify(state)}
          style={{}}
          buttonStyle={{}}
          expires={999}
          sameSite="Strict"
          secure={true}
        >
          This website collects cookies to deliver better user experience{" "}
          <span style={{ fontSize: "12px" }}>
            We collect cookies to analyze our website traffic and performance;
            we never collect any personal data
          </span>
        </CookieConsent>
        <Language
          onClick={() =>
            changeLanguage(i18n.language === "en" ? "fr" : "en")
          }
        >
          <div>{i18n.language === "en" ? "fr" : "en"}</div>
        </Language>
        <Routes>
          <Route exact="true" path="/" element={<HomePage />} />
          <Route path="/fr" element={<HomePage />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/terms-of-use" element={<TermsOfUse />} />
          <Route path="/*" element={<ErrorPage />} />
        </Routes>
      </I18nextProvider>
    </MainDiv>
  );
}
const MainDiv = styled.div`
  height: 100vh;
  width: 100vw;
  .CookieConsent {
    background: rgba(0, 0, 0, 0.7) !important;
    color: var(--secondary) !important;
    box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3), 0 3px 20px 0 rgba(0, 0, 0, 0.3) !important;
    & > div:first-child {
      font-size: 16px;
      display: flex;
      flex-direction: column;
      color: rgba(255, 255, 255, 0.6);
      & > span {
        color: var(--secondary) !important;
      }
    }
    & > div:nth-child(2) {
      align-self: center;
      & > button {
        font-size: 22px;
        border: none;
        font-weight: bold;
        background: inherit !important;
        color: var(--secondary) !important;
        background-color: rgba(0, 0, 0, 0.2) !important;
        box-shadow: 0 3px 8px 0 rgba(0, 0, 0, 0.3),
          0 3px 20px 0 rgba(0, 0, 0, 0.3) !important;
        &:hover {
          background-color: var(--babel-grey) !important;
          color: #101010 !important;
        }
      }
      & > button:nth-child(2) {
        color: rgba(255, 255, 255, 0.6) !important;
        border: 1px solid rgba(255, 255, 255, 0.6) !important;
        &:hover {
          background-color: var(--babel-grey) !important;
          color: #101010 !important;
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    overflow-y: scroll;
  }
`;
const Language = styled.div`
  z-index: 100;
  color: var(--lightblack);
  cursor: pointer;
  position: fixed;
  left: 25px;
  /* top: 15px; */
  bottom: 40px;
  background-color: var(--babel-turquoise);
  text-transform: uppercase;
  border-radius: 30px;
  padding: 5px;
  & > div {
    font-size: 18px;
    font-weight: 400;
  }
  & > div::after {
    content: "";
    display: block;
    width: 0;
    height: 1px;
    background: var(--silver);
    transition: width 0.3s ease-in;
  }
  & > div:hover::after {
    width: 100%;
  }
  &:hover {
    transition: ease-in-out 0.3s;
    color: var(--white);
  }
  @media screen and (max-width: 991px) {
    left: unset;
    right: 25px;
  }
`;
export default React.memo(App);
