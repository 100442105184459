import React, { forwardRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";

const RulesAndRegulations = forwardRef((props, ref) => {
  const { t } = useTranslation();
  return (
    <RulesAndRegulationsDiv ref={ref}>
      {/* change classname to scroll-box3 to add vertical scrolling for a small box if text is too long */}
      <div>
        <h2 title={t("rules-h-o")}>Rules And Regulations</h2>
        <p>{t("rules-p-o")}</p>
        <p>{t("rules-p-t")}</p>
      </div>
    </RulesAndRegulationsDiv>
  );
});
const RulesAndRegulationsDiv = styled.div`
  width: 100vw;
  height: 100vh;
  background-image: url("/img/rules_bg.webp");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: bottom;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  & > div:nth-child(1) {
    ::-webkit-scrollbar {
      width: 8px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.2);
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
    }
    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 1);
    }
    overflow-y: auto;
    scroll-behavior: smooth;
    height: auto;
    max-height: 68vh;
    width: 100%;
    max-width: 600px;
    position: absolute;
    z-index: 1;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 20px 40px;
    margin-right: 40px;
    & > h2 {
      height: auto;
      width: 100%;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      /* align-self: end; */
      /* margin: 20px 200px 20px 0; */
    }
    /* & > img {
      height: auto;
      max-width: 600px;
      width: 100%;
      margin: 0 auto;
    } */
    & > p {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      /* align-self: end; */
      /* margin-right: 200px; */
    }
  }
  @media (max-width: 991px) {
    height: auto;
    min-height: 100vh;
    padding: 20px 0;
    overflow-y: visible;
    background-image: url("/img/rules_bg_mobile.webp");
    /* background-position: top;
    top: 0; */
    justify-content: center;
    align-items: unset;
    & > div:nth-child(1) {
      max-height: unset;
      position: unset;
      justify-content: flex-end;
      padding: 20px;
      margin-right: 0px;
      /* padding-top: 80vw; */
      padding-top: 20vw;
      text-align: center;
      /* & > img {
        width: auto;
      } */
      & > h2 {
        font-size: 36px;
        line-height: 40px;
        margin: 0 0 24px 0;
      }
      & > p {
        max-width: unset;
        width: 100%;
        margin-right: 0;
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
  }
`;
export default RulesAndRegulations;
