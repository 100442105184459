import React, { useEffect } from "react";
import styled, { keyframes, css } from "styled-components";
import { useBabel } from "../context/BabelContext";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { changeLanguage } from "../i18n";
import { Link } from "react-router-dom";

const Navbar = () => {
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { menuBarToggle, viewIndex, navbarVisible },
  } = useBabel();
  const handleClick = () => {
    dispatch({ type: "menu-toggle", payload: false });
  };
  useEffect(() => {
    // Retrieve the language from storage on component mount
    const storedLanguage = localStorage.getItem("language");
    if (storedLanguage) {
      i18n.changeLanguage(storedLanguage);
    }
  }, [i18n]);
  // Set the language in browser storage when it changes
  const handleLanguageChange = (lng) => {
    changeLanguage(lng);
  };
  return (
    <NavbarDiv open={menuBarToggle}>
      <ul>
        <li key={uuidv4()}>
          <a href={`/#${t(`section1`)}`} onClick={() => handleClick()}>
            {t("navbar.menu1")}
          </a>
        </li>
        <li key={uuidv4()}>
          <a href={`/#${t(`section2`)}`} onClick={() => handleClick()}>
            {t("navbar.menu2")}
          </a>
        </li>
        <li key={uuidv4()}>
          <a href={`/#${t(`section3`)}`} onClick={() => handleClick()}>
            {t("navbar.menu3")}
          </a>
        </li>
        <li key={uuidv4()}>
          <a
            href={`/#${t(`section4`)}`}
            onClick={() => {
              handleClick();
            }}
          >
            {t("navbar.menu4")}
          </a>
        </li>
        <li key={uuidv4()}>
          <a
            href={`/#${t(`section5`)}`}
            onClick={() => {
              handleClick();
            }}
          >
            {t("navbar.menu5")}
          </a>
        </li>
        <li key={uuidv4()}>
          <a href={`/#${t(`section6`)}`} onClick={() => handleClick()}>
            {t("navbar.menu6")}
          </a>
        </li>
        <li key={uuidv4()}>
          <a
            href="https://widgets.libroreserve.com/WEB/QC017062041547/book"
            target="_blank"
            onClick={() => handleClick()}
            rel="noreferrer"
          >
            {t("reserve")}
          </a>
        </li>
        <li
          onClick={() =>
            handleLanguageChange(i18n.language === "en" ? "fr" : "en")
          }
        >
          <Link>{i18n.language === "en" ? "fr" : "en"}</Link>
        </li>
      </ul>
      {navbarVisible && (
        <nav>
          <StyledBurger
            theme={{ menuBarToggle }}
            aria-label="hamburger"
            open={menuBarToggle}
            onClick={() => {
              menuBarToggle === true
                ? dispatch({ type: "menu-toggle", payload: false })
                : dispatch({ type: "menu-toggle", payload: true });
            }}
          >
            {" "}
            <div />
            <div />
            <div />
          </StyledBurger>
          <div>
            {viewIndex} <span>{""}</span> {7}
          </div>
          <div>
            <a
              href="https://widgets.libroreserve.com/WEB/QC017062041547/book"
              target="_blank"
              rel="noreferrer"
            >
              {t("reserve")}
            </a>
            {/* <div
              onClick={() =>
                handleLanguageChange(i18n.language === "en" ? "fr" : "en")
              }
            >
              {i18n.language === "en" ? "fr" : "en"}
            </div> */}
          </div>
        </nav>
      )}
    </NavbarDiv>
  );
};
const slide = keyframes`
from {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
`;
const slideBack = keyframes`
from {
    transform: translateX(0%);
  }
  to {
    transform: translateX(-100%);
  }
`;
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const NavbarDiv = styled.div`
  position: fixed;
  z-index: 100;
  height: 60px;
  width: 100vw;
  & > nav:nth-child(2) {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: row nowrap;
    flex: 80px 1fr;
    align-items: center;
    justify-content: space-between;
    border-bottom: 2px solid var(--babel-grey);
    animation: ${fadeIn} 1s;
    & > div:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      color: var(--babel-grey);
      span {
        width: 60px;
        border-bottom: 1px solid var(--babel-grey);
        margin-right: 5px;
        margin-left: 5px;
      }
    }
    & > div:nth-child(3) {
      margin-right: 25px;
      /* width: 95px; */
      color: var(--babel-grey);
      font-size: 16px;
      text-align: center;
      cursor: pointer;
      transition: width 1.2s;
      font-weight: 300;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: 40px;
      &:hover {
        color: var(--white);
      }
    }
    & > div:nth-child(4) {
      background: rgba(0, 0, 0, 0.25);
      width: 100%;
      max-width: 220px;
      position: fixed;
      left: 50%;
      bottom: 74px;
      transform: translate(-50%, calc(-50% + 20px));
      border: 1px solid var(--babel-grey);
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      justify-content: space-between;
      padding: 10px;
      font-weight: 300;
      transition: width 1.2s;
      & > a {
        height: 24px;
        width: 24px;
        & > img {
          height: 100%;
          width: 100%;
          cursor: pointer;
        }
      }
      & > span {
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 16px;
        color: var(--babel-grey);
        span {
          width: 50px;
          border-bottom: 1px solid var(--babel-grey);
          margin-right: 5px;
          margin-left: 5px;
        }
      }
    }
  }
  & > ul {
    display: flex;
    transition: all 0.3s;
    opacity: ${({ open }) => (open ? "1" : "0")};
    z-index: ${({ open }) => (open ? "10" : "-1")};
    pointer-events: ${({ open }) => !open && "none"};
    position: absolute;
    width: 100vw;
    height: 100vh;
    animation: ${({ open }) =>
      open === true &&
      css`
        ${slide} 0.3s ease-in-out forwards
      `};
    animation: ${({ open }) =>
      open === false &&
      css`
        ${slideBack} 0.3s ease-in-out backwards
      `};
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 10;
    background: var(--navbar-bg);
    list-style: none;
    padding: 0;
    margin: 0;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    & > li {
      & > a {
        display: block;
        margin: 5px 20px;
        font-size: 1.8rem;
        color: var(--black);
        text-decoration: none;
        &:hover {
          color: var(--black);
          font-weight: 400;
        }
      }
    }
    & > li:last-child {
      display: none;
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    & > nav:nth-child(2) {
      & > div:nth-child(3) {
        & > div {
          display: none;
        }
      }
    }
  }
`;
const StyledBurger = styled.button`
  flex-basis: 80px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 100%;
  background: transparent;
  border: none;
  font-size: unset;
  padding: 0;
  font-size: unset;
  border-radius: unset;
  cursor: pointer;
  padding: 0;
  z-index: 10;
  border-right: 2px solid ${({ open }) => (open ? "none" : "var(--babel-grey)")};
  &:focus {
    outline: none;
  }
  &:hover {
    background-color: unset;
  }
  & > div {
    width: 30px;
    height: 1px;
    background: ${({ open }) => (open ? "var(--black)" : "var(--babel-grey)")};
    border-radius: 10px;
    position: relative;
    transform-origin: 1px;
    padding-bottom: 2px;
    animation: ${({ open }) => !open && fadeIn} 1s;
    :first-child {
      transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
      margin: 4px 0;
    }

    :nth-child(2) {
      opacity: ${({ open }) => (open ? "0" : "1")};
      transform: ${({ open }) => (open ? "translateX(0)" : "translateX(0)")};
      margin: 4px 0;
    }

    :nth-child(3) {
      transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
      margin: 4px 0;
    }
  }
  @media screen and (min-width: 992px) {
    /* start of desktop styles */
  }
  @media screen and (min-width: 480px) and (max-width: 991px) {
    /* start of medium tablet styles */
  }
  @media screen and (max-width: 479px) {
    /* start of phone styles */
  }
`;
export default Navbar;
