/* eslint-disable no-unused-vars */
import React, { useEffect, useRef, useState } from "react";
import styled from "styled-components";
import { useInView } from "react-intersection-observer";
import ReactPlayer from "react-player";
import LazyLoad from "react-lazyload";
import bgPageThree from "../images/shisha.png";
import { useBabel } from "../context/BabelContext";
import Contact from "../components/Contact";
import Menu from "../components/Menu";
import Flavours from "../components/Flavours";
import { useTranslation } from "react-i18next";
import { SEO } from "../SEO";
import { BiDownArrowAlt } from "react-icons/bi";
import locationBg from "../images/location.png";
// import contactUsBg from "../images/form.png";
import pgLogo from "../images/PLAYGROUND-LOGO.svg";
import Footer from "../components/Footer";
import About from "../components/About";
import RulesAndRegulations from "../components/RulesAndRegulations";
import Mezze from "../components/Mezze";
import Cocktails from "../components/Cocktails";
import { useLocation } from "react-router-dom";
import Brunch from "../components/Brunch";
import ResponsiveImage from "../components/Img/ResponsiveImage";
import Desserts from "../components/Desserts";

const HomePage = () => {
  const {
    dispatch,
    state: { viewIndex },
  } = useBabel();
  const { t, i18n } = useTranslation();
  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    setIsMounted(true);
  }, []);
  const isWideScreen = window.innerWidth > 991;
  const videoUrl = isWideScreen
    ? "assets/videos/BABEL_LP_DT.mp4"
    : "assets/videos/BABEL_LP_MO.mp4";
  const [ref1, inView1] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const [ref2, inView2] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const [ref3, inView3] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const [ref4, inView4] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const [ref5, inView5] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const [ref6, inView6] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });
  const [ref7, inView7] = useInView({
    threshold: 0.5,
    trackVisibility: true,
    delay: 100,
  });

  useEffect(() => {
    let views = [inView1, inView2, inView3, inView4, inView5, inView6, inView7];
    views.map(
      (el, ind) =>
        el === true && dispatch({ type: "view-index", payload: ind + 1 })
    );
  }, [dispatch, inView1, inView2, inView3, inView4, inView5, inView6, inView7]);

  // Function to update the URL hash based on the section being viewed
  const location = useLocation();

  useEffect(() => {
    // Delay in milliseconds
    const delay = 1000;

    const timeoutId = setTimeout(() => {
      // Construct the section ID name based on the viewIndex
      const sectionId = `${t("section" + viewIndex)}`;

      // Construct the new URL hash
      const newHash = `#${sectionId}`;

      // Construct new URL based on current pathname and search parameters, and the new hash
      const newUrl = `${location.pathname}${location.search}${newHash}`;

      // Update the browser's URL without reloading the page
      window.history.replaceState(null, "", newUrl);
    }, delay);

    return () => clearTimeout(timeoutId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, viewIndex, location.pathname, location.search, i18n.language]);

  useEffect(() => {
    const scrollContainer = document.querySelector("main");

    // Handle scrolling events (both wheel and touch)
    const handleScroll = (event) => {
      const viewportWidth = window.innerWidth;

      if (viewportWidth >= 991) {
        // Horizontal scrolling
        scrollContainer.scrollBy({
          left: event.deltaY * 10, // Use deltaY for horizontal scrolling
          top: 0,
          behavior: "smooth",
        });
      } else {
        // Vertical scrolling
        scrollContainer.scrollBy({
          top: event.deltaY,
          left: 0,
          behavior: "smooth",
        });
        dispatch({
          type: "navbar-scroll",
          payload: event.wheelDeltaY > 0 ? true : false,
        });
      }
    };

    // Handle touch move events
    const handleTouchMove = (event) => {
      const touch = event.touches[0];
      const startX = touch.clientX;
      const startY = touch.clientY;
      const viewportWidth = window.innerWidth;
      const deltaYThreshold = 50; // Adjust threshold as needed

      event.target.addEventListener(
        "touchmove",
        function (event) {
          const touchMoveX = event.touches[0].clientX;
          const touchMoveY = event.touches[0].clientY;
          const diffX = startX - touchMoveX;
          const diffY = startY - touchMoveY;

          if (viewportWidth >= 991) {
            if (Math.abs(diffX) > Math.abs(diffY)) {
              // Horizontal scrolling
              scrollContainer.scrollBy({
                left: diffX * 10,
                top: 0,
                behavior: "smooth",
              });
            } else if (Math.abs(diffY) > deltaYThreshold) {
              // Vertical scrolling
              scrollContainer.scrollBy({
                top: diffY,
                left: 0,
                behavior: "smooth",
              });
              const isScrollingDown = diffY > 0;
              dispatch({
                type: "navbar-scroll",
                payload: isScrollingDown,
              });
            }
          }
        },
        { passive: false }
      );
    };

    // Add event listeners
    scrollContainer?.addEventListener("wheel", handleScroll);
    scrollContainer?.addEventListener("touchmove", handleTouchMove);

    // Cleanup function
    return () => {
      scrollContainer?.removeEventListener("wheel", handleScroll);
      scrollContainer?.removeEventListener("touchmove", handleTouchMove);
    };
  }, [dispatch]);

  useEffect(() => {
    let startY = null;

    const handleTouchStart = (event) => {
      startY = event.touches[0].clientY;
    };

    const handleTouchEnd = (event) => {
      if (startY !== null) {
        const endY = event.changedTouches[0].clientY;
        const deltaY = endY - startY;

        if (deltaY < 0) {
          // User swiped up
          dispatch({
            type: "navbar-scroll",
            payload: false,
          });
        } else if (deltaY > 0) {
          // User swiped down
          dispatch({
            type: "navbar-scroll",
            payload: true,
          });
        }
      }
      // Reset startY after touch end
      startY = null;
    };

    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchend", handleTouchEnd);

    return () => {
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [dispatch]);

  return (
    <Main>
      {viewIndex === 1 ? (
        <ArrowLink href={`#${t("section2")}`}>
          <BiDownArrowAlt />
          <div></div>
        </ArrowLink>
      ) : null}
      <Section id={`${t("section1")}`}>
        <HomePageOne ref={ref1}>
          <div>
            <ResponsiveImage
              src="img/logo/babel-logo-wall.png"
              alt="babel-logo"
              fluidImage={true}
              customStyles={{
                height: "auto",
                width: "100%",
                maxWidth: "440px",
                marginBottom: "32px",
                "@media (max-width: 991px)": {
                  maxWidth: "280px",
                },
              }}
            />
            {/* <h1>{t("h-o-h-o")}</h1> */}
            {/* <p>{t("h-o-title")}</p> */}
            {/* <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Corrupti
              cupiditate neque possimus ipsam quasi voluptates?
            </p> */}
          </div>
          <VideoWrapper>
            {isMounted && (
              <LazyLoad height={200} offset={100} once>
                <StyledReactPlayer
                  url={videoUrl}
                  playing
                  loop
                  muted
                  playsinline
                  preload="auto"
                  config={{
                    file: {
                      attributes: {
                        autoPlay: true,
                        muted: true,
                        loop: true,
                        playsInline: true,
                        preload: "auto",
                      },
                    },
                  }}
                />
              </LazyLoad>
            )}
          </VideoWrapper>
          {/* <div>
            <video
              autoPlay
              loop
              muted
              playsInline
              preload="auto"
              src="assets/videos/BABEL_HOMEPAGE.mp4"
            >
              <source src="assets/videos/BABEL_HOMEPAGE.mp4" type="video/mp4" />
              <source src="assets/videos/BABEL_HOMEPAGE.mov" type="video/mov" />
            </video>
          </div> */}
          {/* <div>
            <img src={pgLogo} alt="pg-logo" />
          </div>
          <h2>{t("coming.soon")}</h2> */}
          <SEO
            item={{
              title: t("seo-title"),
              description: t("seo-description"),
              url: "https://babelpg.ca/",
            }}
          />
        </HomePageOne>
      </Section>
      <Section id={`${t("section2")}`} ref={ref2}>
        <About />
      </Section>
      <Section id={`${t("section3")}`}>
        <FlavoursSection ref={ref3}>
          <ResponsiveImage
            src="img/white-bg2.jpg"
            alt="background"
            fluidImage
            customStyles={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              objectFit: "cover",
              zIndex: -1,
            }}
          />
          <Flavours
            view3={{
              inView3,
              inView4,
            }}
          />
        </FlavoursSection>
      </Section>
      {/* <Section id={`${t("section4")}`}>
        <MenuSection ref={ref4}>
          <Menu view6={inView4} />
        </MenuSection>
      </Section> */}
      <Section id={`${t("section4")}`}>
        <Mezze ref={ref4} />
      </Section>
      <Section id={`${t("section5")}`}>
        <Cocktails ref={ref5} />
      </Section>
      <Section id={`${t("section6")}`}>
        <Desserts ref={ref6} />
      </Section>
      {/* <Section id={`${t("section6")}`}>
        <RulesAndRegulations ref={ref6} />
      </Section> */}
      <Section id={`${t("section7")}`}>
        <ContactSection ref={ref7}>
          <Contact />
          <Footer />
        </ContactSection>
      </Section>
    </Main>
  );
};
const ArrowLink = styled.a`
  position: fixed;
  z-index: 10;
  text-decoration: none;
  left: calc(50% - 17px);
  bottom: 4%;
  transform: translateX(-50%, -50%);
  font-size: 30px;
  border: 2px solid var(--babel-grey);
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  color: var(--babel-grey);
  cursor: pointer;
  & > div {
    margin-top: 15px;
  }
  @media screen and (min-width: 992px) {
    display: none;
  }
`;
const Main = styled.main`
  height: 100vh;
  width: 100vw;
  display: flex;
  overflow-y: auto;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  scroll-behavior: smooth; /* Add smooth scrolling behavior */
  transition: scroll-snap-type 0.1s ease; /* Add transition for scroll-snap-type changes */
  @media (max-width: 991px) {
    display: block;
    overflow-y: scroll; /* Enable vertical scrolling */
    overflow-x: hidden; /* Hide horizontal scrollbar */
    scroll-snap-type: none; /* Set vertical scrolling behavior */
  }
`;
const Section = styled.section`
  height: 100vh;
  min-width: 100vw;
  scroll-snap-align: start;
  position: relative;
  /* overflow: hidden; */
  @media (max-width: 991px) {
    height: auto;
    overflow: hidden;
    scroll-snap-align: unset;
  }
`;
const HomePageOne = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  overflow: hidden;
  div:nth-child(1) {
    display: flex;
    flex-flow: column wrap;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%;
    position: absolute;
    z-index: 2;
    & > img {
      height: auto;
      width: 100%;
      max-width: 440px;
      margin-bottom: 32px;
      /* display: none; */
    }
    & > h1 {
      text-align: center;
      height: auto;
      max-width: 800px;
      width: 100%;
      margin: 0 auto;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
      text-shadow: none;
    }
    & > p {
      text-align: center;
      font-size: 16px;
      line-height: 24px;
      max-width: 590px;
      width: 100%;
    }
  }
  div:nth-child(3) {
    /* display: none; */
    position: absolute;
    top: 40px;
    right: 40px;
    z-index: 5;
    width: 100%;
    max-width: 200px;
    /* & > img {
      width: 100%;
      height: 100%;
    } */
  }
  & > h2:nth-child(4) {
    /* display: none; */
    padding: 0 20px;
    position: absolute;
    left: 50%;
    bottom: 15%;
    transform: translate(-50%, -50%);
    z-index: 5;
    /* color: var(--white); */
    font-size: 32px;
    width: 100%;
    text-align: center;
  }
  @media (max-width: 991px) {
    height: auto;
    min-height: 100vh;
    overflow: hidden;
    & > div:first-child {
      /* & > img {
        max-width: 280px;
      } */
      position: relative;
      padding: 80px 20px;
      height: auto;
      min-height: 100vh;
      & > h1 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 24px;
      }
      & > p {
        max-width: unset;
        width: 100%;
        margin-right: 0;
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
  }
`;
const FlavoursSection = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;

  @media screen and (max-width: 991px) {
    padding: 60px 20px;
    height: auto;
    background-position: right;
    top: 0;
  }
`;
const MenuSection = styled.div`
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`;
const ContactSection = styled.div`
  width: 100vw;
  height: 100vh;
  /* background: url(${locationBg}); */
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    height: auto;
    min-height: 100vh;
    padding: 20px 0;
    overflow-y: visible;
    background-size: contain;
    background-position: top;
    top: 0;
  }
`;
// const FormSection = styled.div`
//   width: 100vw;
//   height: 100vh;
//   /* background: url(${contactUsBg}); */
//   background-size: cover;
//   background-repeat: no-repeat;
//   background-position: left;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   position: relative;
//   @media screen and (max-width: 991px) {
//     /* start of phone and medium tablet styles */
//     height: auto;
//     min-height: 100vh;
//     padding: 20px 0;
//     overflow-y: visible;
//     background-size: contain;
//     background-position: top;
//     top: 0;
//   }
// `;
const VideoWrapper = styled.div`
  width: 100%;
  height: 100%;
  opacity: 0.6;
  position: absolute;
  top: 0;
  z-index: 1;
`;

const StyledReactPlayer = styled(ReactPlayer)`
  position: relative;
  top: 0;
  left: 0;
  width: 100% !important;
  height: 100% !important;
  video {
    object-fit: cover;
  }
`;
export default React.memo(HomePage);
