/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import styled, { keyframes, css } from "styled-components";
import { useBabel } from "../context/BabelContext";
import { useTranslation } from "react-i18next";
import ResponsiveImage from "./Img/ResponsiveImage";

const Flavours = ({ inView3, inView4 }) => {
  const { t, i18n } = useTranslation();
  const {
    dispatch,
    state: { category, activeFlavor, shishaData },
  } = useBabel();
  const [fade, setFade] = useState(false);

  const handleCategory = (value) => {
    dispatch({ type: "category", payload: value });
    setFade(false);
  };

  const handleFlavors = (flavor) => {
    dispatch({ type: "active-flavor", payload: flavor });
    setFade(true);
    const section = document.getElementById(t("section3"));
    if (section) {
      section.scrollIntoView({ behavior: "smooth", block: "start" });
    }
  };

  const found_active_flavors = shishaData.filter(
    (flavor) => flavor.category?.toLowerCase() === category?.toLowerCase()
  );
  return (
    <>
      <FirstDiv key={activeFlavor} view3={{ inView3, fade, inView4 }}>
        <aside>
          <div>{activeFlavor?.category.toLocaleUpperCase()} /</div>
          <h2>
            {i18n.language === "en"
              ? activeFlavor?.title
              : activeFlavor?.title_fr}{" "}
            ·{" "}
            <span>
              {i18n.language === "en"
                ? "$" + activeFlavor?.price
                : activeFlavor?.price + "$"}
            </span>
          </h2>
          <p>
            {i18n.language === "en"
              ? activeFlavor?.description
              : activeFlavor?.description_fr}
          </p>
          <div>
            <a
              href={`/pdf/BABEL_SHISHA_${i18n.language.toLocaleUpperCase()}.pdf`}
              rel="noreferrer"
              alt="shisha"
              target="_blank"
            >
              {i18n.language === "en" ? "Shisha menu" : "Le menu shisha"}
            </a>
          </div>
        </aside>
        <ResponsiveImage
          src={`img/shisha/ITEM0${activeFlavor?.flavor}.webp`}
          alt="shisha-1"
          fluidImage={true}
          customStyles={{
            justifySelf: "center",
            alignSelf: "end",
            width: "100%",
            maxWidth: "500px",
            height: "100%",
            maxHeight: "500px",
            objectFit: "contain",
            objectPosition: "center",
            "@media (max-width: 991px)": {
              height: "240px",
              alignSelf: "center",
            },
          }}
        />
        <aside>
          {i18n.language === "en" ? (
            <div>
              <h3>Shisha Prices</h3>
              <p>
                <span>Regular:</span> <span>$30</span>
              </p>
              <p>
                <span>Premium:</span> <span>$35</span>
              </p>
              <p>
                <span>Baba:</span> <span>$75</span>
              </p>
              <p>
                <span>Kaloud Head:</span> <span>$5</span>
              </p>
            </div>
          ) : (
            <div>
              <h3>Prix de Shisha</h3>
              <p>
                <span>Regulier:</span> <span>30$</span>
              </p>
              <p>
                <span>Premium:</span> <span>35$</span>
              </p>
              <p>
                <span>Baba:</span> <span>75$</span>
              </p>
              <p>
                <span>Tête Kaloud:</span> <span>5$</span>
              </p>
            </div>
          )}
          {i18n.language === "en" ? (
            <div>
              <h3>Prices Refill</h3>
              <p>
                <span>Refill - Regular:</span>
                <span>$15</span>
              </p>
              <p>
                <span>Refill - Premium:</span>
                <span>$20</span>
              </p>
              <p>
                <span>Refill - Baba:</span>
                <span>$30</span>
              </p>
              <p>
                <span>Kaloud head:</span>
                <span>$5</span>
              </p>
            </div>
          ) : (
            <div>
              <h3>Prix des recharges</h3>
              <p>
                <span>Recharge - Regulier:</span>
                <span>$15</span>
              </p>
              <p>
                <span>Recharge - Premium:</span>
                <span>$20</span>
              </p>
              <p>
                <span>Recharge - Baba:</span>
                <span>$30</span>
              </p>
              <p>
                <span>Tête Kaloud:</span>
                <span>$5</span>
              </p>
            </div>
          )}
        </aside>
      </FirstDiv>
      <SecondDiv
        view3={{ inView3, fade, category, active: activeFlavor.category }}
      >
        <div>
          <span key={activeFlavor}>
            {found_active_flavors?.map((el, ind) => (
              <div
                key={el.title}
                onClick={() => handleFlavors(el)}
                className={
                  activeFlavor.category === category &&
                  activeFlavor.flavor === el.flavor
                    ? "active"
                    : undefined
                }
              >
                {i18n.language === "en" ? el?.title : el?.title_fr}
              </div>
            ))}
          </span>
        </div>
        <div>
          <h2
            className={category === "House Blends" ? "active" : undefined}
            onMouseOver={() =>
              category !== "House Blends" && handleCategory("House Blends")
            }
          >
            {i18n.language === "en" ? "House Blends" : "Mélanges Maison"}
            <span
              className={
                activeFlavor.category === "House Blends" ? "active" : undefined
              }
            ></span>
          </h2>
          <h2
            className={category === "Exotic Mixes" ? "active" : undefined}
            onMouseOver={() =>
              category !== "Exotic Mixes" && handleCategory("Exotic Mixes")
            }
          >
            {i18n.language === "en" ? "Exotic Mixes" : "Mélanges Exotiques"}
            <span
              className={
                activeFlavor.category === "Exotic Mixes" ? "active" : undefined
              }
            ></span>
          </h2>
          <h2
            className={category === "Classics" ? "active" : undefined}
            onMouseOver={() =>
              category !== "Classics" && handleCategory("Classics")
            }
          >
            {i18n.language === "en" ? "Classics" : "Classiques"}
            <span
              className={
                activeFlavor.category === "Classics" ? "active" : undefined
              }
            ></span>
          </h2>
          <h2
            className={category === "Nicotine Free" ? "active" : undefined}
            onMouseOver={() =>
              category !== "Nicotine Free" && handleCategory("Nicotine Free")
            }
          >
            {i18n.language === "en" ? "Nicotine Free" : "Sans Nicotine"}
            <span
              className={
                activeFlavor.category === "Nicotine Free" ? "active" : undefined
              }
            ></span>
          </h2>
        </div>
      </SecondDiv>

      <Link
        href={`/pdf/BABEL_SHISHA_${i18n.language.toLocaleUpperCase()}.pdf`}
        rel="noreferrer"
        alt="shisha"
        target="_blank"
      >
        {i18n.language === "en" ? "Shisha menu" : "Le menu shisha"}
      </Link>
    </>
  );
};
const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;
const SlideIn = keyframes`
  0% {
    transform: translateY(+100%);
    opacity: 0;
  }
  60% {
    opacity: 0;
  }
  100% {
    transform: translateY(0%);
    opacity: 1;
  }
`;
const FirstDiv = styled.div`
  height: 500px;
  width: 100%;
  max-width: 1440px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  /* grid-template-columns: repeat(2, 1fr); */
  /* align-items: center; */
  gap: 10px;
  /* padding: 40px; */
  & > aside:nth-child(1) {
    width: 100%;
    height: 100%;
    align-self: flex-start;
    margin-top: 20px;
    /* display: flex;
    flex-direction: column;
    justify-content: center; */
    & > h2 {
      font-size: 40px;
      line-height: 60px;
      margin-bottom: 10px;
      text-transform: uppercase;
      font-weight: 700;
      & > span {
        color: var(--black);
      }
    }
    & > p:nth-child(3) {
      line-height: 24px;
      margin-bottom: 10px;
      color: #000;
    }
    & > div:nth-child(4) {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 24px 0;
      gap: 20px;
      & > a {
        border: 1px solid var(--babel-grey);
        font-size: 16px;
        padding: 10px;
        width: 100%;
        max-width: 250px;
        text-align: center;
        cursor: pointer;
        transition: width 1.2s;
        font-weight: 300;
        background: var(--babel-turquoise);
        color: var(--black);
        /* background: rgba(0, 0, 0, 0.25); */
        &:hover {
          background: var(--title-color);
          border: 1px solid var(--title-color);
        }
      }
      & > p {
        line-height: 24px;
        font-size: 20px;
        font-weight: 300;
        text-transform: uppercase;
        margin: 0;
        margin-right: 30px;
      }
    }
    & > a {
      font-size: 16px;
      padding: 10px;
      width: 100%;
      max-width: 300px;
      border: 1px solid var(--babel-grey);
      color: var(--babel-grey);
      text-align: center;
      cursor: pointer;
      transition: width 0.8s;
      font-weight: 300;
      background: rgba(0, 0, 0, 0.25);
      /* margin-top: 20px; */
      &:hover {
        border: 1px solid var(--babel-turquoise);
        background: var(--babel-turquoise);
        color: var(--black);
      }
    }
  }
  & > img:nth-child(2) {
    animation: ${({ view3 }) =>
      view3.fade === true &&
      css`
        ${fadeIn} 1.4s forwards
      `};
  }
  & > aside:nth-child(3) {
    width: 100%;
    align-self: flex-end;
    margin-bottom: 20px;
    display: flex;
    justify-content: flex-end;
    gap: 60px;
    & > div {
      justify-self: flex-end;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      & > h3 {
        text-transform: uppercase;
        width: max-content;
        font-size: 25px;
      }
      & > p {
        margin-bottom: 5px;
        width: 100%;
        color: #000;
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 40px;
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    height: auto;
    min-height: unset;
    display: block;
    padding: 0;
    & > aside:nth-child(1) {
      align-self: unset;
      height: auto;
      & > h2 {
        font-size: 36px;
        line-height: 40px;
        margin-bottom: 12px;
      }
      & > p {
        font-size: 14px;
        line-height: 22px;
        margin-bottom: 24px;
      }
      & > div:nth-child(4) {
        flex-direction: column-reverse;
        justify-content: center;
        margin-top: 0;
        & > p {
          margin-right: 0;
        }
        & > a {
          display: none;
        }
      }
    }
    & > aside:nth-child(3) {
      /* display: none; */
      gap: 10px;
      justify-content: center;
      margin-top: 20px;
      & > div {
        width: 100%;
        & > h3 {
          font-size: 16px;
          margin: 10px 0;
        }
        & > p {
          gap: 10px;
          font-size: 12px;
        }
      }
    }
  }
`;
const SecondDiv = styled.div`
  height: 100px;
  width: 100%;
  max-width: 1440px;
  display: flex;
  flex-flow: column nowrap;
  justify-content: center;
  align-items: center;
  padding-top: 100px;
  & > div:nth-child(1) {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: flex-end;
    position: relative;
    & > span {
      text-align: center;
      position: absolute;
      left: ${({ view3 }) => view3.category === "adalya" && "0%"};
      left: ${({ view3 }) => view3.category === "lit" && "24%"};
      left: ${({ view3 }) => view3.category === "mint" && "43%"};
      left: ${({ view3 }) => view3.category === "fruit" && "62%"};
      display: flex;
      flex-flow: row nowrap;
      color: var(--title-color);
      justify-content: space-around;
      align-items: flex-end;
      gap: 30px;
      ${({ view3 }) =>
        view3.fade === false &&
        css`
          animation: ${SlideIn} 0.5s ease-in;
        `}
      & > div {
        justify-content: space-between;
        cursor: pointer;
        font-weight: 300;
        &::after {
          content: "";
          display: block;
          width: 0;
          height: 1px; /* Increased border height */
          background: var(--babel-grey);
          transition: width 0.3s ease-in;
          margin-top: 4px; /* Adjusted margin */
        }
        &:hover::after {
          width: 100%;
        }
        &.active::after {
          width: 100%;
        }
      }
    }
  }
  & > div:nth-child(2) {
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    justify-content: space-around;
    align-items: flex-end;
    gap: 5px;
    position: relative;
    h2 {
      font-size: 22px;
      width: 25%;
      border: 1px solid var(--babel-grey);
      border-radius: 30px;
      text-align: center;
      cursor: pointer;
      transition: width 0.8s;
      font-weight: 300;
      position: relative;
      &:hover {
        width: 50%;
        background: var(--babel-turquoise);
        color: var(--black);
        border: 1px solid var(--babel-turquoise);
      }
      &.active {
        width: 50%;
        background: var(--babel-turquoise);
        color: var(--black);
        border: 1px solid var(--babel-turquoise);
        & > span {
          &.active {
            display: block;
            background: var(--black);
          }
        }
      }
      & > span {
        position: absolute;
        right: 10px;
        bottom: 5px;
        z-index: 10;
        height: 20px;
        width: 20px;
        border-radius: 50%;
        display: none;
        &.active {
          display: block;
          background: var(--babel-grey);
        }
      }
    }
  }
  @media screen and (max-width: 991px) {
    /* start of phone and medium tablet styles */
    padding: 10px 0;
    display: block;
    height: auto;
    & > div:nth-child(1) {
      /* display: none; */
      margin-bottom: 12px;
      justify-content: center;
      align-items: center;
      height: auto;
      & > span {
        position: relative;
        left: unset;
        gap: 5px;
        font-size: 12px;
        flex-flow: row wrap;
        & > div {
          font-weight: 300;
          padding: 5px 0;
        }
      }
    }
    & > div:nth-child(2) {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin: 0;
      gap: 0;
      height: auto;
      & > h2 {
        width: 100%;
        font-size: 16px;
        margin-bottom: 12px;
        &:hover {
          width: 100%;
        }
        &.active {
          width: 100%;
        }
        & > span {
          bottom: 3px;
          height: 16px;
          width: 16px;
        }
      }
    }
  }
`;
const Link = styled.a`
  border: 1px solid var(--babel-grey);
  font-size: 16px;
  padding: 10px;
  width: 100%;
  max-width: 250px;
  text-align: center;
  cursor: pointer;
  transition: width 1.2s;
  font-weight: 300;
  background: var(--babel-turquoise);
  color: var(--black);
  /* background: rgba(0, 0, 0, 0.25); */
  &:hover {
    background: var(--title-color);
    border: 1px solid var(--title-color);
  }
  @media screen and (min-width: 991px) {
    display: none;
  }
`;
export default React.memo(Flavours);
