import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import { BabelProvider } from "./context/BabelContext";
import GlobalStyle from "./GlobalStyles";
import { BrowserRouter as Router } from "react-router-dom";
import reportWebVitals from "./reportWebVitals";
import "./i18n";
import { createBrowserHistory } from "history";
import { HelmetProvider } from "react-helmet-async";

export const history = createBrowserHistory({
  basename: process.env.PUBLIC_URL,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <BabelProvider>
      <GlobalStyle />
      <HelmetProvider>
        <Router>
          <App />
        </Router>
      </HelmetProvider>
    </BabelProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
