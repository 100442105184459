import React, { forwardRef, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import ResponsiveImage from "./Img/ResponsiveImage";
import useWindowSize from "../utils/useWindowSize";

const About = forwardRef(() => {
  const { t } = useTranslation();
  const parallaxContainerRef = useRef(null);
  const windowSize = useWindowSize();

  const isMobile = windowSize.width <= 991;
  const imageSrc = isMobile
    ? "img/babel-lounge-story-mobile.webp"
    : "img/babel-lounge-story.webp";

  const parallax = (event) => {
    parallaxContainerRef.current.querySelectorAll(".mouse").forEach((shift) => {
      const position = shift.getAttribute("value");
      const x = (window.innerWidth - event.pageX * position) / 45;
      const y = (window.innerHeight - event.pageY * position) / 45;

      shift.style.transform = `translateX(${x}px) translateY(${y}px)`;
    });
  };

  useEffect(() => {
    const container = parallaxContainerRef.current;

    const handleMouseMove = (event) => {
      if (window.innerWidth > 768) {
        parallax(event);
      }
    };

    if (container) {
      container.addEventListener("mousemove", handleMouseMove);

      return () => {
        container.removeEventListener("mousemove", handleMouseMove);
      };
    }
  }, []);

  return (
    <AboutDiv ref={parallaxContainerRef}>
      <div className="scroll-box">
        <h2 title={t("about-h-o")}>{t("about-h-o")}</h2>
        <p>{t("about-p-o")}</p>
        <p>{t("about-p-t")}</p>
        <p>{t("about-p-three")}</p>
      </div>
      <ResponsiveImage
        src={imageSrc}
        alt="ambiance-bg"
        className="mouse"
        value="1"
        fluidImage={true}
        customStyles={{
          objectFit: "cover",
          objectPosition: "center",
          position: "absolute",
          height: "105%",
          width: "105%",
          top: "-5%",
          left: "-5%",
          "@media (max-width: 991px)": {
            height: "auto",
            width: "100%",
            objectFit: "cover",
            top: "-10px",
            left: "0",
            right: "0",
            // left: "-4%",
          },
        }}
      />
    </AboutDiv>
  );
});

const AboutDiv = styled.div`
  width: 100vw;
  height: 100vh;
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  overflow: hidden;

  & > div.scroll-box {
    ::-webkit-scrollbar {
      width: 8px;
    }
    ::-webkit-scrollbar-track {
      border-radius: 8px;
      background-color: rgba(255, 255, 255, 0.2);
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(255, 255, 255, 0.8);
      border-radius: 10px;
    }
    ::-webkit-scrollbar-thumb:hover {
      background: rgba(255, 255, 255, 1);
    }
    overflow-y: auto;
    scroll-behavior: smooth;
    height: auto;
    width: 100%;
    max-width: 600px;
    position: absolute;
    z-index: 1;
    flex-flow: column nowrap;
    justify-content: center;
    padding: 20px 40px;
    margin-right: 40px;

    & > h2 {
      height: auto;
      width: 100%;
      font-size: 40px;
      line-height: 60px;
      text-transform: uppercase;
    }

    & > p {
      font-size: 16px;
      line-height: 24px;
      width: 100%;
      font-weight: 300;
    }
  }

  @media (max-width: 991px) {
    height: auto;
    min-height: 100vh;
    padding: 0;
    overflow-y: visible;
    background-size: contain;
    background-position: top;
    top: 0;
    justify-content: center;
    align-items: unset;

    & > div.scroll-box {
      max-height: unset;
      position: relative;
      justify-content: flex-end;
      margin-right: 0px;
      padding: 80vw 20px 60px 20px;
      text-align: center;

      & > h2 {
        font-size: 36px;
        line-height: 40px;
        margin: 0 0 24px 0;
      }

      & > p {
        max-width: unset;
        width: 100%;
        margin-right: 0;
        font-size: 16px;
        margin-bottom: 12px;
      }
    }
  }
`;

export default About;
